//Service exclusive reducer

import {
  SERVICE_CHECK_BEGIN,
  SERVICE_FETCH_BEGIN,
  SERVICE_FETCH_SUCCESS,
  SERVICE_FETCH_FAILURE,
  SERVICE_REFRESH,
  SERVICE_SAVE_BEGIN,
  SERVICE_SAVE_SUCCESS,
  SERVICE_SAVE_FAILURE,
  SERVICE_SAVE_FAILURE_INVOKERS,
  SERVICE_DELETE,
  SERVICE_UPDATE,
  SERVICE_PATCH,
  SERVICE_ACTIVE_FILTER_UPDATE,
  SERVICE_OVERRIDE_ERROR,
  SERVICE_DISCARD_OVERRIDE_ERROR_BEGIN,
  SERVICE_DISCARD_OVERRIDE_ERROR_END,
  GET_SERVICE_UPDATES_BEGIN,
  GET_SERVICE_UPDATES_FINISHED,
  STATUS_TRANSITION_BEGIN,
  STATUS_TRANSITION_SUCCESS,
  STATUS_TRANSITION_FAILURE,
  CLEAR_ERROR_WRAP,
  ISSUE_MANDATORY_PARAMETER_OFF,
  ISSUE_MANDATORY_PARAMETER_ON,
  ISSUE_MANDATORY_REQUIRED,
  SERVICE_DELETE_FAILURE,
  SERVICE_DELETE_SUCCESS,
  DISCARD_DELETE_SERVICE
} from '../actions/types';

const initialState = {
  data: {
    formalParameters: [],
    instructions: [],
    entities: [],
    selectedInstruction: null,
    currentServiceInstructionParameters: null,
    activeFilter: false,
  },
  loading: false,
  error: null,
  saving: false,
  isEditInstructionParameters: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SERVICE_CHECK_BEGIN:
      // Reset any errors.
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SERVICE_FETCH_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors.
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SERVICE_FETCH_SUCCESS:
      // Success: set loading to false.
      // Also, replace the service with the one fetched from api.
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload.service,
          selectedInstruction:
            action.payload.service.selectedInstruction === undefined
              ? null
              : action.payload.service.selectedInstruction,
          currentServiceInstructionParameters:
            action.payload.service.currentServiceInstructionParameters ===
            undefined
              ? null
              : action.payload.service.currentServiceInstructionParameters,
        },
      };

    case SERVICE_FETCH_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have a service to display anymore, so empty service object ((REVIEW THIS, IF WE WANT TO KEEP PREVIOUS SERVICE ALIVE)).
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: {
          formalParameters: [],
          instructions: [],
          selectedInstruction: null,
          currentServiceInstructionParameters: null,
        },
      };
    case SERVICE_DELETE:
      // When required to clean up current service, this is called.
      return initialState;

    case SERVICE_UPDATE:
      // This section is for updating information within service scope.
      // It updates Selected Instruction index, and its parameters.
      return {
        ...state,
        data: {
          ...state.data,
          selectedInstruction: action.payload.selectedInstruction,
          currentServiceInstructionParameters:
            action.payload.currentServiceInstructionParameters?.map((c) => ({
              ...c,
              isValid: c.isValid !== undefined ? c.isValid : true,
            })),
        },
      };

    case SERVICE_PATCH:
      // This section is for updating information within service scope.
      // It updates only the parameters given through payload
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload.paramsToUpdate,
        },
      };

    case SERVICE_ACTIVE_FILTER_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          activeFilter: action.payload.activeFilter,
        },
      };

    case STATUS_TRANSITION_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case STATUS_TRANSITION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case STATUS_TRANSITION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }

    case SERVICE_SAVE_BEGIN:
      return {
        ...state,
        saving: true,
        error: null,
        savedService: undefined,
      };

    case GET_SERVICE_UPDATES_BEGIN:
      return {
        ...state,
        saving: true,
        error: null,
      };

    case GET_SERVICE_UPDATES_FINISHED:
      return {
        ...state,
        saving: false,
        error: null,
      };

    case SERVICE_REFRESH:
      // Success: set saving to false.
      // Also, replace the service with the one fetched from api.
      const selectedInstruction =
        action.payload.service.selectedInstruction === undefined ||
        action.payload.service.selectedInstruction === null
          ? null
          : action.payload.service.selectedInstruction;
      let currentServiceInstructionParameters;
      if (
        action.payload.service.currentServiceInstructionParameters ===
          undefined ||
        action.payload.service.currentServiceInstructionParameters === null
      ) {
        currentServiceInstructionParameters = null;
      } else {
        currentServiceInstructionParameters =
          action.payload.service.currentServiceInstructionParameters.map(
            (param) => {
              if (param.modified) {
                param.modified = null;
              }
              return param;
            }
          );
      }
      return {
        ...state,
        saving: false,
        data: {
          ...action.payload.service,
          selectedInstruction,
          oldConcatenatedName: undefined,
          oldProject: undefined,
          oldType: undefined,
          currentServiceInstructionParameters,
          index:
            action.payload.service.index === undefined
              ? null
              : action.payload.service.index,
          parent:
            action.payload.service.parent === undefined
              ? null
              : action.payload.service.parent,
        },
      };

    case SERVICE_OVERRIDE_ERROR:
      const overrideErrors = state.data.instructions.find(
        (instruction) => instruction.id === getId(action.payload.primitiveId)
      ).instructionError.override;
      const currentExpressionEvaluation = state.data.instructions.find(
        (instruction) => instruction.id === getId(action.payload.primitiveId)
      ).instructionError.expressionEvaluation;
      const newExpressionEvaluation = action.payload.error
        ? currentExpressionEvaluation.filter(
            (expression) => expression.error?.id !== action.payload.error.id
          )
        : [];
      return {
        ...state,
        data: {
          ...state.data,
          instructions: state.data.instructions.map((instruction) => {
            if (instruction.id === getId(action.payload.primitiveId)) {
              return {
                ...instruction,
                instructionError: {
                  ...instruction.instructionError,
                  override: overrideErrors.find(
                    (overError) =>
                      overError.codeToOverride === action.payload.codeToOverride
                  )
                    ? overrideErrors.map((overError) => {
                        if (
                          overError.codeToOverride ===
                          action.payload.codeToOverride
                        ) {
                          return {
                            ...overError,
                            isOverride: true,
                            codeToOverride: action.payload.codeToOverride,
                            newError: action.payload.error,
                            isNew: true,
                          };
                        } else return overError;
                      })
                    : [
                        ...overrideErrors,
                        {
                          tempId: getRandomVal(),
                          isOverride: true,
                          codeToOverride: action.payload.codeToOverride,
                          newError: action.payload.error,
                          isNew: true,
                        },
                      ],
                  expressionEvaluation: [
                    ...newExpressionEvaluation,
                    ...action.payload.expressionEvaluation,
                  ],
                },
              };
            } else {
              return instruction;
            }
          }),
        },
      };

    case SERVICE_DISCARD_OVERRIDE_ERROR_BEGIN:
      return {
        ...state,
        saving: true,
        error: null,
      };

    case SERVICE_DISCARD_OVERRIDE_ERROR_END:
      return {
        ...state,
        saving: false,
        error: null,
      };
    
      case SERVICE_SAVE_SUCCESS:
      // The request failed. It's done. So set saving to "false".
      // Save the error, so we can display it somewhere.
      return {
        ...state,
        saving: false,
        savedService: true
      };

    case SERVICE_SAVE_FAILURE:
      // The request failed. It's done. So set saving to "false".
      // Save the error, so we can display it somewhere.
      return {
        ...state,
        saving: false,
        savedService: undefined
      };
    
      case SERVICE_SAVE_FAILURE_INVOKERS:
      // The request failed. It's done. So set saving to "false".
      // Save the error, so we can display it somewhere.
      return {
        ...state,
        saving: false,
        savedService: false,
      };

    case CLEAR_ERROR_WRAP:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload.paramsToUpdate,
        },
      };
    case 'SET_IS_EDIT_PARAM':
      return {
        ...state,
        isEditInstructionParameters: action.payload,
      };

    case ISSUE_MANDATORY_PARAMETER_OFF:
      return {
        ...state,
        issueMandatory: false,
      }
    
    case ISSUE_MANDATORY_PARAMETER_ON:
      return {
        ...state,
        issueMandatory: action.payload.value.success,
      }
    
    case ISSUE_MANDATORY_REQUIRED:
      return {
        ...state,
        issueMandatoryRequired: action.payload.isNecesaryToAdd
      }
    
    case SERVICE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedService: true
      }

    case SERVICE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        deletedService: false
      }
    
    case DISCARD_DELETE_SERVICE:
      return {
        ...state,
        loading: false,
        deletedService: undefined
      }

    default:
      return state;
  }
}

function getRandomVal() {
  const crypto = window.crypto;
  const int32Array = new Uint32Array(1);
  return crypto.getRandomValues(int32Array)[0];
}

function getId(id) {
  return ('' + id).includes('_tmp') ? id : parseInt(id);
}
